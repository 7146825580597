import React from 'react';
import SEO from 'components/seo';

// Component
import TextImageBlock from 'components/organisms/Blocks/TextImageBlock/TextImageBlock';
import Hero from 'components/molecules/Hero/Hero';

// Data
import WoonVloerenData from 'data/WoonVloerenPage.json';

const WoonVloerenPage = () => (
  <>
    <SEO title="Woonvloeren" />
    <TextImageBlock
      data={WoonVloerenData.intro}
    />
    <Hero
      modifier="inPage"
      {...WoonVloerenData.inPageHero}
    />
  </>
);
export default WoonVloerenPage;
